<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="/img/logo-dialog.405b5032.svg" alt=""/>
    </b-link>
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1"> {{ $t("Forgot Password?") }}</b-card-title>
        <b-card-text class="mb-2">
          {{ $t("Enter your email and we'll send you instructions to reset your password") }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group :label="$t('Email')" label-for="forgot-password-email">
              <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
              >
                <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="example@domain.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-center">
              <!-- submit button -->
              <b-button type="submit" class="btn-login">
                {{ $t("Send reset link") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $t("Back to login") }}
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password -->
    </div>
    <a
        class="mercatus-logo"
        href="https://cooling.mercatus.pt/pt/home"
        target="_blank"
    >
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt=""/>
    </a>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import CostLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import {required, email} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    CostLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      // validation
      required,
      email,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.$store
          .dispatch("users/postForgotPassword", {
            context: this,
            email: this.userEmail,
          })
          .then((sucess) => {
            if (sucess) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Email Sent",
                  icon: "CheckIcon",
                  text:"We have e-mailed your password reset link",
                  variant: "success",
                },
              });
              this.$router.push({ name: "auth-login" });
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: error.response.data,
              },
            });
          });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
